<template>
  <div>
    <Menu />
    <!-- 横幅 -->
    <div class="aboutus">
      <div class="faraise-bac">
        <h1>商业化人工智能综合服务提供商</h1>
      </div>
    </div>

    <!-- 关于我们 -->
    <div class="header">
      <div class="header-hea">
        <h1 class="word-size word-margin word-interval">关于我们</h1>
      </div>
      <div class="hedaer-nav">
        <p>
          <span class="font-wei">武汉慧灵控科技有限公司</span>是一家以<span
            class="font-col"
            >寻求加速数字化转型进程的大型企业</span
          >为目标用户,<span class="font-wei">以构建智能劳动力(RPA)体系</span
          >为核心服务，提供智能自动化增强，视觉及语义分析等解决方案的<span
            class="font-col"
            >设计研发</span
          >与<span class="font-col">落地实现</span>的综合商业人工智能服务商。
        </p>
        <p>
          公司主要服务包括<span class="font-col"
            >机器人流程自动化方案、财务套装软件和定制化系统开发和实施、办公自动化系统及插件开发、无纸化办公</span
          >解决方案等。具体业务场景包括财务共享中心的智能化转型、呼叫中心自动化处理、税务自动填写和申报、异构平台的轻量化自动对接等。
        </p>
        <p>
          公司是<span class="font-col"
            >国内最早的认证RPA许可证代理和机器人实施运维的合作伙伴</span
          >并在短期内为包括电信、制造、科技、零售、保险等行业大型企业提供了从<span
            class="font-col"
            >许可证销售、需求梳理、技术试点、RPA开发实施及运维等全生命周期的深入和专业的服务</span
          >
          。
        </p>
      </div>
    </div>
    <div class="faraise-introduce-manage temp-width">
      <h1 class="word-size">软件及企业证书</h1>
      <div class="aboutus-introduce-manage-box certificate">
        <img src="../../assets/images/aboutus/hlk.png" alt="" />
        <img src="../../assets/images/aboutus/xlry.png" alt="" />
      </div>
    </div>

    <div class="faraise-introduce-manage temp-width" style="margin-top: 50px">
      <div class="aboutus-introduce-manage-box certificate-one">
        <img src="../../assets/images/aboutus/rcj.png" alt="" />
        <img src="../../assets/images/aboutus/xlry1.png" alt="" />
      </div>
    </div>
    <!-- 企业定位 -->
    <div class="temp-width firm-location">
      <h1 class="word-size">企业文化</h1>
    </div>
    <div class="aboutus-location temp-width">
      <div class="aboutus-location-one">
        <h1>企业背景</h1>
        <p>成为市场领先的</p>
        <p>商业人工智能综合服务提供商</p>
      </div>
      <div class="aboutus-location-two">
        <h1>企业定位</h1>
        <p>加速数字化转型、构建劳动力体系</p>
        <p>提供智能自动化增加视觉语义分析等智能方案</p>
        <p>的设计研发与落地实现</p>
      </div>
      <div class="aboutus-location-three">
        <h1>企业使命</h1>
        <p>推广AI商业化应用</p>
        <p>提升企业数字竞争力</p>
      </div>
    </div>

    <Stren />
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Menu from "../../components/menu.vue";
import Stren from "../../components/stren.vue";
export default {
  components: {
    Menu,
    Stren,
  },
};
</script>
<style lang="less" scoped>
.aboutus {
  width: 100%;
  height: 400px;
  background: url("../../assets/images/aboutus/banner_05.png");
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  .aboutus-bac {
    width: 1200px !important;
    margin: 0 auto;
  }

  h1 {
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    line-height: 430px;
  }
}
.header {
  width: 1200px;
  margin: 0 auto;
  .header-hea {
    text-align: center;
  }
  .hedaer-nav {
    letter-spacing: 1px;
    p {
      font-size: 16px;
      line-height: 35px;
      text-indent: 35px;
      margin-bottom: 30px;
    }

    .font-col {
      color: #3373f2;
      font-weight: 400;
    }
    .font-wei {
      font-weight: 600;
      color: #3373f2;
    }
  }
}

.font-co {
  color: #808080;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 30px;
  padding-top: 20px;
}
.certificate img {
  width: 575px;
  height: 380px;
}
.certificate-one img {
  width: 575px;
  height: 720px;
}
</style>
