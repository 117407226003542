<template>
  <div>
    <!-- 首页 -->
    <div>
      <div class="header">
        <img src="../assets/images/login/hkl2.0.png" alt="" />
        <ul>
          <li>
            <router-link to="/" exact >首  页</router-link>
          </li>
          <li>
            <router-link to="/smallspirt">凌动设计器</router-link>
          </li>
          <li>
            <router-link to="/fartoraise">凌动驾驶舱</router-link>
          </li>
          <li>
            <router-link to="/robot">凌动执行器</router-link>
          </li>
          <li>
            <router-link to="/about">关于我们</router-link>
          </li>
          <li>
             <img class="img1" src="../assets/images/login/contacts.png" alt="">
             <span style="font-size: 22px;color: #5595ff;">400-083-1886</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      current: 0
    }
  },
  methods: {
    go(index) {
      this.current = index
    }
  }
}
</script>

<style lang="less" scoped>
div {
  width: 100%;
  .header {
    width: 1200px;
    height: 90px;
    line-height: 90px;
    margin: 0 auto;
    display: flex;
    img {
      width: 150px;
      height: 40px;
      padding-top: 28px;
    }
    ul {
      margin-left: 210px;
      li {
        display: inline-block;
        margin-left: 20px;
        font-size: 16px;
        .router-link-active{
         color: #1989fa;
         border-bottom:3px solid  #1989fa;
         padding-bottom:10px;
          }
        .img1 {
          width: 30px;
          height: 30px;
          margin-bottom: 33px;
          vertical-align: middle;
        }
        a {
          margin: 0 17px;
          color: #282c34;
          margin-bottom:10px;
        }
        span {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
