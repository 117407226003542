<template>
  <div>
    <div class="section">
      <div class="section-header">
        <h1>武汉慧灵控科技有限公司</h1>
        <p>北京<i></i>上海<i></i>深圳<i></i>广州<i></i>武汉<i></i>大连<i></i>香港</p>
        <p class="section-header-p">contact@whhlktech.com</p>
        <p class="section-advice-p">咨询电话： 400-083-1886</p>
        
      </div>
    </div>
    <div class="sectbottom">
    <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鄂ICP备2021017496号-1</a></p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.section {
  width: 100%;
  height: 230px;
  background: #1f2a2f;
  margin-top: 60px;
  border-top: solid #1f2a2f 4px;
  .section-header {
    text-align: center;
    h1 {
      //  text-align:center;
      color: #fff;
      font-size: 22px;
      font-weight: 400;
      margin-top: 60px;
      margin-bottom: 30px;
    }
    p {
      font-size: 16px;
      color: #fff;
    }
    i {
      height: 5px;
      width: 5px;
      background-color: #fff;
      border-radius: 50%;
      display: inline-block;
      margin: 0 20px;
    }
    .section-header-p {
      font-size: 16px;
      color: #fff;
      margin: 30px 0 20px;
    }
    .section-advice-p{
      font-size: 14px;
    }
  }
}
.sectbottom {
  width: 100%;
  height: 40px;
  background-color: #182328;
  line-height: 40px;
  p {
    text-align: center;
    font-size: 14px;
    color: #fff;
    span {
      padding-left: 10px;
    }
    a{
      color: #fff;
    }
  }
}
</style>
