  <template>
  <div>
    <Menu />
    <!-- 横幅 -->
    <div class="faraise">
      <div class="faraise-bac">
        <h1>凌动执行器 ROBOT</h1>
        <p class="word-p-spa" style="color: #fff;">它可以在运行各种环境下，包括虚拟环境</p>
      </div>
    </div>

    <!-- 产品介绍 -->
    <div class="faraise-introduce temp-width">
      <div class="faraise-introduce-con robort-interval">
        <h1 class="word-size">产品介绍</h1>
        <p class="word-p-spa" style="text-align: center">
          分为前台办公机器人和后台办公机器人。它可运行在各种环境下，包括虚拟环境。
        </p>
      </div>
      <!-- 介绍 -->
      <div class="faraise-introduce-img">
        <img src="../../assets/images/robot/picture_15.png" alt="" />
        <img src="../../assets/images/robot/picture_16.png" alt="" />
      </div>

      <div class="faraise-introduce-manage">
        <h1 class="word-size">系统配置</h1>
        <div class="faraise-introduce-manage-box">
          <div>
            <h2>流程列表</h2>
            <p class="word-p-spa robort-col">流程列表展示本地流程列表和凌动驾驶舱流程列表，展示内容有流程名称、上次运行时间，当鼠标悬停在目录上时显示该项目的名称和当前版本。流程按上次运行时间倒序排列，上次运行时间取运行流程的开始时间。</p>
<!--            <p class="word-p-spa robort-col">端口：服务器端口</p>-->
<!--            <p class="word-p-spa robort-col">-->
<!--              密钥：机器密钥。从睿筹君【计算机管理】中获取。-->
<!--            </p>-->
<!--            <p class="word-p-spa robort-col">-->
<!--              连接：IP、端口、密钥输入正确，点击可连接到服务器。-->
<!--            </p>-->
<!--            <p class="word-p-spa robort-col">-->
<!--              断开连接：断开与服务器的连接。未连接到服务器时，-->
<!--              点击后提示：未在连接状态，无法断开连接。-->
<!--            </p>-->
<!--            <p class="word-p-spa robort-col">机器人日志</p>-->
<!--            <p class="word-p-spa robort-col">-->
<!--              日志级别：机器人日志级别分为Verbose\Trace\Information\Warning\Error\Critical\Off。-->
<!--            </p>-->
          </div>
          <img src="../../assets/images/robot/picture_18.png" alt="" />
        </div>
      </div>

      <div class="faraise-introduce-manage">
        <div class="faraise-introduce-manage-box manage-right">
          <img src="../../assets/images/robot/picture_19.png" alt="" />
          <div class="faraise-introduce-manage-right" style='margin-left: 40px'>
            <h2>运行记录</h2>
            <p class="word-p-spa robort-col">
              运行记录页面用来展示流程执行的历史记录，每条记录包括:
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>作业编号：</span>当前作业记录的jobkey;
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>流程名称：</span>当前记录执行的流程名称;
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>版本：</span>当前记录执行的流程版本号;
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>标签：</span>可以看到该流程是本地流程还是在线流程;
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>创建时间：</span>流程创建时间;
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>开始时间：</span>流程运行启动时间;
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>结束时间：</span>流程运行结束时间;
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>流程状态：</span>等待中、执行中、已停止、成功、失败;
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>操作：</span>流程日志和日志分析。
            </p>
          </div>
        </div>
      </div>

      <div class="faraise-introduce-manage">
        <div class="faraise-introduce-manage-box">
          <div>
            <h2>排班管理</h2>
            <p class="word-p-spa robort-col">
              排班管理将展示本地/驾驶舱排班，支持创建排班并在指定时间自动
              执行相关流程。
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>动态授权-无人值守：</span>排班管理不可用且页面不展示。
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>动态授权-有人值守：</span>本地/驾驶舱排班可用，页面正常展示本地/驾
              驶舱排班。
            </p>
            <p class="word-p-spa robort-col">
              <span style='font-weight: 500;color: #1f2a2f'>离线授权：</span>排班本地排班可用，驾驶舱排班禁用，页面仅展示本地排班。
            </p>
            <p class="word-p-spa robort-col">
              注:当存在启用状态排班时，进行切换授权操作，所有排班将被禁用。
            </p>
          </div>
          <img src="../../assets/images/robot/picture_20.png" alt="" />
        </div>
      </div>
    </div>
    <Stren />
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Menu from "../../components/menu.vue";
import Stren from "../../components/stren.vue";
export default {
  components: {
    Menu,
    Stren,
  },
};
</script>
<style lang="less" scoped>
.faraise {
  width: 100%;
  height: 400px;
  background: url("../../assets/images/robot/banner_04.png");
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  .faraise-bac {
    width: 1200px !important;
    margin: 0 auto;
  }

  h1 {
    font-size: 36px;
    font-weight: 500;
    padding-top: 145px;
  }
  p {
    margin-top: 30px;
    font-size: 16px;
  }
}
</style>
