<template>
  <div>
    <Menu />
    <!-- 轮播图 -->
    <div class="login">
      <el-carousel indicator-position="outside" class="login-head">
        <el-carousel-item class="login-head-lbt1">
          <img src="../../assets/images/login/banner_01.png" alt="" />
          <div class="login-head-lbt1-wz">
            <p>构建企业级超自动化工作平台</p>
            <p class="login-head-lbt1-wz1">加速数字化变革及运营进程</p>
          </div>
        </el-carousel-item>
        <el-carousel-item class="login-head-lbt2">
          <img src="../../assets/images/login/banner_06.png" alt="" />
          <div class="login-head-lbt1-wz">
            <p class="login-head-lbt1-wz2">
              通过数字化技术将企业运行向操作机器化、信息电子化和系统自动化转变
            </p>
            <p class="login-head-lbt1-wz1">数字转型化</p>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 关于我们 -->
    <div class="temp-width login-wide">
      <img src="../../assets/images/login/picture_01.png" alt="" />
      <div class="login-explain">
        <h1>关于我们</h1>
        <p>
          <span style="font-weight: 600">武汉慧灵控科技有限公司</span
          >是一家以<span>寻求加速数字化转型进程的大型企业</span>为目标用户，<span>以构建智能劳动力(RPA)体系</span>为核心服务，提供智能自动化增强，视觉及语义分析等解决方案的<span>设计研发</span>与<span>落地实现</span>的综合商业人工智能服务商。
        </p>
        <p>
          公司主要服务包括<span>机器人流程自动化方案、财务套装软件和定制化系统开发和实施、办公自动化系统及插件开发、无纸化办公</span>解决方案等。具体业务场景包括财务共享中心的智能化转型、呼叫中心自动化处理、税务自动填写和申报、异构平台的轻量化自动对接等。
        </p>
        <p>
          公司是<span>国内最早的认证RPA许可证代理和机器人实施运维的合作伙伴</span>并在短期内为包括电信、制造、科技、零售、保险等行业大型企业提供了从<span>许可证销售、需求梳理、技术试点、RPA开发实施及运维等全生命周期的深入和专业的服务</span>。
        </p>
      </div>
    </div>

    <!-- 我们的主要业务 -->
    <div class="login-business">
      <div class="temp-width">
        <h1 class="word-size">我们主要的业务</h1>
        <p>根据行业特征及细分业务场景深度定制，一站式引领业务升级</p>
        <div class="login-business-lightspot">
          <div>
            <img src="../../assets/images/login/picture_02.png" alt="" />
            <p>机器人流程自动化开发和实施</p>
            <div class="mask">
              <h1>机器人流程自动化开发和实施</h1>
              <p>
                在客户企业智能化转型规划框架下，协助客户对目标业务及流程进行梳理和优化，准确识别需要试用机器人进行自动化的流程业务特点。
                选取合适的技术及产品进行自动化开发及部署，并进行完整的测试和上线。在上线运营过程中，提供相应的运维支撑服务。
              </p>
            </div>
          </div>
          <div>
            <img src="../../assets/images/login/picture_03.png" alt="" />
            <p>机器人流程自动化软件许可证销售</p>
            <div class="mask">
              <h1>机器人流程自动化软件许可证销售</h1>
              <p>
                公司是国内最早的认证RPA许可证代理和机器人实施运维的合作伙伴并在短期内为包括电信、制造、科技、零售、保险等行业大型企业提供了从许可证销售、需求梳理、技术试点、RPA开发实施及运维等全生命周期的深入和专业的服务。
              </p>
            </div>
          </div>
          <div>
            <img src="../../assets/images/login/picture_04.png" alt="" />
            <p>财务套装软件和定制化系统开发实施</p>
            <div class="mask">
              <h1>财务套装软件和定制化系统开发实施</h1>
              <p>
                具体业务场景包括财务共享中心的智能化转型、呼叫中心自动化处理、税务自动填写和申报、异构平台的轻量化自动对接等。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 智能化产品 -->
    <div class="temp-width login-product">
      <h1 class="word-size">丰富可靠的智能化产品</h1>
      <p class="word-p">计算、存储、网络、大数据、优质IT资源助力企业发展</p>
      <!-- tab切换 -->
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="凌动设计器" name="first">
          <div class="tab-content">
            <div>
              <h1>凌动设计器介绍</h1>
              <p>
                凌动设计器是我司自主开发的RPA (Robotic Process Automation)
                流程监控管理平台，能够编排执行重复的业务流程自动执行日常的重复操作，实现工作流程的自动化。
                RPA业务流程管控平台可以管理环境中资源的创建，监视和部署,
                它充当与第三方解决方案和应用程序的集成点，可通过uipath的oc调用机器人、资产和流程，也可直接调用本地流程。
              </p>
            </div>
            <img src="../../assets/images/login/picture_05.png" alt="" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="凌动驾驶舱" name="second">
          <div class="tab-content">
            <div>
              <h1>凌动驾驶舱介绍</h1>
              <p>
                凌动驾驶舱使您能够在执行重复的业务流程时协调一个高度可扩展的服务器平台，主要承担管理者角色。主要是集中调度、管理和监控所有机器人，通过中央控制中心，您可以掌握机器人的部署和工作情况，对流程进行综合管理。
              </p>
            </div>
            <img src="../../assets/images/login/picture_06.png" alt="" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="凌动执行器" name="third">
          <div class="tab-content">
            <div>
              <h1>凌动执行器介绍</h1>
              <p>
                凌动执行器分为本地版，服务器版。
                机器人安装后，需要获取激活后方可使用，
                可以手动操作机器人运行这些流程，也可以通过服务器控制机器人执行流程。
              </p>
            </div>
            <img
              src="../../assets/images/login/picture_07.png"
              style="width: 747px"
              alt=""
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 公司介绍横幅 -->
    <div class="login-bac">
      <div class="login-con">
        <div class="login-bac-content" v-for="(v, index) in bounceList" :key="index">
          <span>
            <animate-number from="0" :to="v.num" duration="3000"></animate-number
          ></span>
          <span>+</span>
          <p>{{ v.name }}</p>
        </div>
      </div>
    </div>

    <!-- 客户及合作伙伴 -->
    <div class="login-bacg">
      <div class="login-partner">
        <h1 class="word-size">客户及合作伙伴</h1>
        <p>帮助更多客户获得成功</p>

        <!-- 轮播图 -->
        <el-carousel indicator-position="outside">
          <el-carousel-item>
            <img src="../../assets/images/login/partner_01.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../../assets/images/login/partner_02.png" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!-- 客户保障 -->
    <div class="login-safeguard temp-width">
      <h1 class="word-size">安全合格的多重保障</h1>
      <p>来自全球权威机构的认证,保证客户业务环境安全可靠</p>
      <div class="login-safeguard-category">
        <div>
          <img src="../../assets/images/login/ISO9001.png" alt="" />
          <p>质量管理体系认证</p>
        </div>
        <div>
          <img src="../../assets/images/login/ISO27001.png" alt="" />
          <p>质量管理体系认证</p>
        </div>
        <div>
          <img src="../../assets/images/login/CMMI3.png" alt="" />
          <p>质量管理体系认证</p>
        </div>
        <div>
          <img src="../../assets/images/login/srrd.png" alt="" />
          <p>质量管理体系认证</p>
        </div>
      </div>
    </div>
    <Stren />
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Menu from "../../components/menu.vue";
import Stren from "../../components/stren.vue";
export default {
  components: {
    Menu,
    Stren,
  },
  data() {
    return {
      activeName: "first",
      bounceList: [
        {
          name: "RPA高级开发技术人员",
          num: 100,
        },
        {
          name: "服务企业 (世界)",
          num: 500,
        },
        {
          name: "项目服务经验",
          num: 1000,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 600px;
  margin: 0 auto;
  .login-head {
    .login-head-lbt1 {
      position: relative;
      .login-head-lbt1-wz {
        position: absolute;
        top: 255px;
        left: 730px;
        font-size: 36px;
        font-weight: 400;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        line-height: 60px;
        text-align: justify;
        .login-head-lbt1-wz1 {
          margin-left: 15px;
        }
      }
      img {
        width: 100%;
        cursor: pointer;
        height: 600px;
        transition: all 0.6s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }
    .login-head-lbt2 {
      .login-head-lbt1-wz {
        position: absolute;
        top: 255px;
        left: 730px;
        font-size: 36px;
        font-weight: 400;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        line-height: 60px;
        text-align: justify;
      }
      .login-head-lbt1-wz1 {
        margin-left: 115px;
        font-weight: 600;
        letter-spacing: 5px;
      }
      .login-head-lbt1-wz2 {
        margin-left: -356px;
        margin-top: -34px;
        margin-bottom: 26px;
      }
      img {
        cursor: pointer;
        width: 100%;
        height: 600px;
        transition: all 0.6s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }
    .login-head-lbt3 {
      .login-head-lbt1-wz {
        position: absolute;
        top: 255px;
        left: 730px;
        font-size: 36px;
        font-weight: 400;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        line-height: 60px;
        text-align: justify;
      }
      .login-head-lbt1-wz12 {
        margin-left: 145px;
        font-weight: 600;
        letter-spacing: 5px;
      }
      .login-head-lbt1-wz2 {
        margin-left: -291px;
        margin-top: -22px;
        margin-bottom: 26px;
      }
      img {
        width: 100%;
        height: 600px;
        cursor: pointer;
        transition: all 0.6s;
      }
      img:hover {
        transform: scale(1.2);
      }
    }
  }
}

/deep/.el-carousel__container {
  height: 600px;
}
/* tab切换 */
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
  margin-top: 60px;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-left: none;
  width: 250px;
  height: 50px;
  background-image: linear-gradient(#2867f7, #479bfd);
  line-height: 43px;
  border-radius: 50px;
  color: #fff;
  border: #9ecffe solid 3px;
  font-size: 20px;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(1) {
  margin-right: 225px;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2) {
  margin-right: 225px;
}
/deep/.el-tabs__item.is-active {
  background-image: linear-gradient(#ff4b4f, #ff7773) !important;
  border: #ffb4b3 solid 3px !important;
}
</style>
