<template>
  <div>
    <Menu />

    <!-- 横幅 -->
    <div class="faraise">
      <div class="faraise-bac">
        <h1>凌动驾驶舱服务监控平台</h1>
        <p class="word-p-spa" style="color: #fff;">对机器人工作站进行综合调度与权限控制</p>
      </div>
    </div>

    <!-- 产品介绍 -->
    <div class="faraise-introduce temp-width">
      <div class="faraise-introduce-con robort-interval">
        <h1 class="word-size">产品介绍</h1>
        <p class="word-p-spa">
          凌动驾驶舱平台使您能够在执行重复的业务流程时协调一个高度可扩展的服务器平台，主要承担管理者角色。主要是集中调度、管理和监控所有机器人，通过中央控制中心，您可以掌握机器人的部署和工作情况，对流程进行综合管理。
        </p>
      </div>
      <!-- 介绍 -->
      <div class="faraise-introduce-img">
        <img src="../../assets/images/faraise/picture_11.png" alt="" />
        <img src="../../assets/images/faraise/picture_12.png" alt="" />
      </div>
      <!-- 介绍管理 -->
      <div class="faraise-introduce-manage">
        <h1 class="word-size">机器人管理和流程调度</h1>
        <div class="faraise-introduce-manage-box">
          <div>
            <h2>机器人管理</h2>
            <p class="word-p-spa robort-col">
              一站式机器人集群管理、流程任务分发，定时或队列计划，大大提高机器人群组的利用率。
            </p>
          </div>
          <img src="../../assets/images/faraise/picture_13.png" alt="" />
        </div>
      </div>

      <div class="faraise-introduce-manage">
        <div class="faraise-introduce-manage-box manage-right">
          <img src="../../assets/images/faraise/picture_14.png" alt="" />
          <div class="faraise-introduce-manage-right">
            <h2>流程调度</h2>
            <p class="word-p-spa robort-col">
              可自由配置流程间协作及不同流程触发条件，满足需要多种流程配合完成的复杂场景。
            </p>
          </div>
        </div>
      </div>
    </div>
    <Stren />
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Menu from "../../components/menu.vue";
import Stren from "../../components/stren.vue";
export default {
  components: {
    Menu,
    Stren,
  },
};
</script>
<style lang="less" scoped>
.faraise {
  width: 100%;
  height: 400px;
  background: url("../../assets/images/faraise/banner_03.png");
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  .faraise-bac {
    width: 1200px !important;
    margin: 0 auto;
  }

  h1 {
    font-size: 36px;
    font-weight: 500;
    padding-top: 145px;
  }
  p {
    margin-top: 30px;
    font-size: 16px;
  }
}
</style>
