<template>
  <div>
    <Menu />
    <!-- 横幅 -->
    <div class="smallRuyi">
      <div class="smallRuyi-bac">
        <h1>凌动设计器企业智能自动化平台</h1>
        <p class="word-p-spa" style="color: #fff;">
          提升组织生产力和办公效率，实现智能时代的人机协同
        </p>
      </div>
    </div>

    <!-- 产品介绍 -->
    <div class="smallRuyi-introduce temp-width">
      <div class="smallRuyi-headline">
        <h1 class="word-size word-margin">产品介绍</h1>
        <p class="word-p-spa word-p-introduce">
          凌动设计器是我司自主开发的RPA(Robotic Process Automation)流程监控管理平台，能编排执行重复的业务流程自动执行日常的重复操作，实现工作流的自动化。RPA业务流程管控平台可以管理环境中资源的创建，监视和部署,它充当与第三方解决方案和应用程序的集成点，可通过uipath的OC调用机器人、资产和流程，也可直接调用本地流程。
        </p>
        <div class="word-div">
          <img src="../../assets/images/smallSpiritRuyi/interface_02.png" alt="">
          <img src="../../assets/images/smallSpiritRuyi/interface_01.png" alt="">
        </div>
      </div>
      <div class="smallRuyi-content">
      </div>
    </div>

    <div class="smallRuyi-introduce temp-width">
      <div class="smallRuyi-headline">
        <p class="word-p-spa">
          2017年底开始自主研发，对标行业领头羊UiPath，面向大型企业及集团公司的自动化应用领域，提供完整的智能自动化服务解决平台。
        </p>
      </div>
      <div class="smallRuyi-content">
        <div class="smallRuyi-content-category">
          <div class="smallRuyi-content-category-bac">
            <span>产品组件</span>
          </div>
          <div class="smallRuyi-content-category-cont">
            <i></i>
            <p>凌动设计器</p>
          </div>
          <div class="smallRuyi-content-category-cont">
            <i></i>
            <p>凌动驾驶舱</p>
          </div>
          <div class="smallRuyi-content-category-cont">
            <i></i>
            <p>凌动执行器</p>
          </div>
        </div>
        <div class="smallRuyi-content-category">
          <div class="smallRuyi-content-category-bac">
            <span>研发时间</span>
          </div>
          <p class="smallRuyi-content-category-time">
            2017年12月正式推出，100%自主研发
          </p>
        </div>
        <div class="smallRuyi-content-category">
          <div class="smallRuyi-content-category-bac">
            <span>技术对标</span>
          </div>
          <div class="smallRuyi-content-category-cont">
            <i></i>
            <p>Uipath</p>
          </div>
          <div class="smallRuyi-content-category-cont">
            <i></i>
            <p>Blue Prism</p>
          </div>
        </div>
        <div class="smallRuyi-content-category">
          <div class="smallRuyi-content-category-bac">
            <span>产品版本</span>
          </div>
          <p class="smallRuyi-content-category-time">
            每6个月迭代一次主要版本，目前版本 为V3.1.0%自主研发
          </p>
        </div>
        <div class="smallRuyi-content-category">
          <div class="smallRuyi-content-category-bac">
            <span>插件数量</span>
          </div>
          <p class="smallRuyi-content-category-time">
            包括WPS、foxmail、用友等主流国内
            应用插件70+，无缝衔接Uipath插件%自主研发
          </p>
        </div>
        <div class="smallRuyi-content-category">
          <div class="smallRuyi-content-category-bac">
            <span>主要客户</span>
          </div>
          <p class="smallRuyi-content-category-time">
            中国电信、中石化、中交二航局、 舞东风集团、泰康集团、某金融集团
          </p>
        </div>
      </div>
    </div>

    <!-- 产品组件 -->
    <div class="smallRuyi-module-bac">
      <div class="temp-width smallRuyi-module">
        <div class="smallRuyi-headline">
          <h1 class="word-size">产品组件</h1>
          <p class="word-p-spa">
            凌动企业RPA平台由凌动设计器studio、凌动驾驶舱服务监控平台、凌动执行器
            robot三部分构成
          </p>
        </div>
        <img src="../../assets/images/smallSpiritRuyi/picture_12.png" alt="" />
        <div class="smallRuyi-headline-hand">
          <p>设计器</p>
          <p>监控平台</p>
          <p>ROBOT</p>
        </div>
        <div class="smallRuyi-module-kind">
          <div class="smallRuyi-module-kind-con">
            <p>
              即机器人的开发工具，拥有极其便捷且稳定的开发框架众多的预置组件，满足所有日常需要具备强大的扩展功能，提供Python.C/C++、.Net等多种编程语言的扩展接口
            </p>
          </div>
          <div class="smallRuyi-module-kind-con">
            <p>
              即机器人的管理中心，对机器人工作站进行综合调度与权限控制。可实现信息统一管理，提供数据可视化图表展示。
            </p>
          </div>
          <div class="smallRuyi-module-kind-con">
            <p>
              分为前台办公机器人和后台办公机器人。它可运行在各种环境下，包括虚拟环境。
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 功能展示 -->
    <div class="temp-width smallRuyi-reveal">
      <h1 class="word-size">功能展现</h1>
      <div class="smallRuyi-reveal-bac">
        <!-- 左边内容 -->
        <div class="smallRuyi-reveal-content" style="float: left">
          <div class="smallRuyi-reveal-content-left">
            <img
              src="../../assets/images/smallSpiritRuyi/picture_11.png"
              alt=""
            />
            <div>
              <h1>开发逻辑</h1>
              <p>
                通过应用场景划分组件模块，几乎所有
                功能都可用通过拖拽组装的方式来实现。
              </p>
            </div>
          </div>
          <div class="smallRuyi-reveal-content-left">
            <img
              src="../../assets/images/smallSpiritRuyi/picture_11.png"
              alt=""
            />
            <div>
              <h1>IDE开发环境</h1>
              <p>
                既能通过拖拽方式开发，亦可结合code模块使用VB.Net开发，也可插入Python。
              </p>
            </div>
          </div>
          <div class="smallRuyi-reveal-content-left">
            <img
              src="../../assets/images/smallSpiritRuyi/picture_11.png"
              alt=""
            />
            <div>
              <h1>数据库支持</h1>
              <p>支持通过内建控件可以方便连接数据库进行增删查改操作。</p>
            </div>
          </div>
          <div class="smallRuyi-reveal-content-left">
            <img
              src="../../assets/images/smallSpiritRuyi/picture_11.png"
              alt=""
            />
            <div>
              <h1>图形展示模型</h1>
              <p>
                类似流程图的展示模式，展示有严格的逻辑区域划分，如if和else
                if的一定在不同的区域。
              </p>
            </div>
          </div>
        </div>
        <!--右边内容 -->
        <div class="smallRuyi-reveal-content" style="float: right">
          <div class="smallRuyi-reveal-content-left">
            <img
              src="../../assets/images/smallSpiritRuyi/picture_11.png"
              alt=""
            />
            <div>
              <h1>开发便利性</h1>
              <p>
                类似流程图的展示模式，但只能上下展示有严格的逻辑区域划分，如if和else
                if的一定在不同的区域。
              </p>
            </div>
          </div>
          <div class="smallRuyi-reveal-content-left">
            <img
              src="../../assets/images/smallSpiritRuyi/picture_11.png"
              alt=""
            />
            <div>
              <h1>机器人执行监控</h1>
              <p>可以通过睿筹君管理平台对每个机器人进行详细的运行监控。</p>
            </div>
          </div>
          <div class="smallRuyi-reveal-content-left">
            <img
              src="../../assets/images/smallSpiritRuyi/picture_11.png"
              alt=""
            />
            <div>
              <h1>任务分发</h1>
              <p>支持通过内建控件可以方便连接数据库进行增删查改操作。</p>
            </div>
          </div>
          <div class="smallRuyi-reveal-content-left">
            <img
              src="../../assets/images/smallSpiritRuyi/picture_11.png"
              alt=""
            />
            <div>
              <h1>日志分析</h1>
              <p>打开本地log、服务端log查看，可自定义日志呈现方式。</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 部署方式 -->

    <div class="smallRuyi-deploy">
      <div class="temp-width">
        <div class="smallRuyi-headline">
          <h1 class="word-size">部署方式</h1>
          <p class="word-p-spa">
            小灵如意支持多种物理的部署方式，以满足不同方式下的企业用户需求
          </p>
        </div>
        <div class="smallRuyi-deploy-img">
          <div>
            <img
              src="../../assets/images/smallSpiritRuyi/computer.png"
              alt=""
            />
          </div>
          <div>
            <img src="../../assets/images/smallSpiritRuyi/rocket.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 产品对比 -->
    <div class="smallRuyi-contrast temp-width">
      <h1 class="word-size">凌动VS国内其他RPA软件</h1>
      <img src="../../assets/images/smallSpiritRuyi/form.png" alt="" />
      <div>
        <p>以我们充分的实施经验，</p>
        <p>
          国内主流RPA软件基本需要投入150%-200%的人天以完成同样复杂度流程的自动化。
        </p>
      </div>
    </div>
    <Stren />
    <el-backtop></el-backtop>
  </div>
</template>

<script>
import Menu from '../../components/menu.vue'
import Stren from '../../components/stren.vue'
export default {
  components: {
    Menu,
    Stren
  }
}
</script>
<style lang="less" scoped>
.smallRuyi {
  width: 100%;
  height: 400px;
  background: url('../../assets/images/smallSpiritRuyi/banner_02.png');
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  h1 {
    font-size: 42px;
    font-weight: 500;
    padding-top: 100px;
  }
  p {
    margin-top: 30px;
    font-size: 16px;
  }
}
</style>
